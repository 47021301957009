import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
// 加载vant核心组件库
import Vant, { Dialog, Lazyload, Toast } from 'vant'
// 加载vant全局样式
import 'vant/lib/index.css'
// 加载全局样式
import './styles/index.less'
// 加载动态设置 rem 基准值
import 'amfe-flexible'

import VueCookies from 'vue-cookies'
import VideoPlayer from 'vue-video-player'
import APlayer from '@moefe/vue-aplayer'

import moment from 'moment'
// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function (input, fmtstring) { // 当input为时间戳时，需转为Number类型
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring)
})

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(Lazyload)

Vue.use(APlayer, {
  defaultCover: '',
  productionTip: true
})

Vue.use(Dialog)
Vue.use(Toast)
Vue.use(VueAxios, axios)

Vue.use(VueCookies)

// 注册使用Vant组件库
Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
